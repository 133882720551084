<template>
    <div>
        <b-sidebar backdrop id="add_users" width="50rem" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right :title="formtitle" shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{formtitle}}</span>
                </div>
                <div @click="hide" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>اغلاق</span>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="4">
                        <label>{{ $parent.lang.choose_employee }}</label>
                        <div>
                            <b-form-select class="selborder" 
                                v-model="user.empid"
                                :options="employees"
                                @change="userfullname()"
                             />
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <label>{{ $parent.lang.username }} *</label>
                        <b-form-input class="inborder"
                            v-model="user.username"
                        />
                    </v-col>
                    <v-col cols="4">
                        <label>{{ $parent.lang.full_name }} *</label>
                        <b-form-input class="inborder"
                            v-model="user.full_name"
                        />
                    </v-col>
                    <v-col cols="4">
                        <label>{{ $parent.lang.password }} *</label>
                        <b-form-input class="inborder"
                            v-model="user.password"
                        />
                    </v-col>
                    <v-col cols="4" v-if="$store.state.licenseType.families">
                        <v-text-field
                            label="نسبة التحصيل "
                            v-model="user.vperc"
                            >{{ user.password }}</v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <label>{{ $parent.lang.permissions }}</label>
                        <div>
                            <b-form-select class="selborder"
                                v-model="user.group_id"
                                :options="perms"
                             />
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <label>مركز الدخل</label>
                        <div>
                            <b-form-select class="selborder"
                                v-model="user.cost_center"
                                :options="constsCenters"
                             />
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <label>اختر المستودع</label>
                        <div>
                            <b-form-select class="selborder"
                                v-model="user.storeid"
                                :options="isStores"
                             />
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <label>اختر الفرع</label>
                        <div>
                            <b-form-select class="selborder"
                                v-model="user.branch_id"
                                :options="isBranches"
                             />
                        </div>
                    </v-col>
                </v-row>
            </div>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" @click='addUser()' class="ma-2" style="width:100px;">{{ SUBMIT_NAME }}</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import axios from 'axios';
export default {
    data() {
        return {
            perms:[
                {
                    text: this.$parent.lang.cashier,
                    value: 3,
                },
                {
                    text: this.$parent.lang.accountant,
                    value: 2,
                },
                {
                    text: this.$parent.lang.administratior,
                    value: 1,
                },
                // {
                //     text: this.$parent.lang.vendors,
                //     value: 4,
                // },
                {
                    text: this.$parent.lang.salesman,
                    value: 5,
                },
                {
                    text: this.$parent.lang.superVisor,
                    value: 6,
                },
                {
                    text: this.$parent.lang.technical,
                    value: 7,
                },
            ],
            permissions:{
                text: this.$parent.lang.cashier,
                value: 3,
            },
            formtitle: this.$parent.lang.add_user,
            SUBMIT_NAME: this.$parent.lang.add,
            employees: [],
            user:{
                cost_center: 1,
                id: 0,
                username: '', 
                full_name: '', 
                password: '', 
                group_id: 3, 
                created_by: 0, 
                created_date: '', 
                updated_by: 0, 
                updated_date: '', 
                company_id: 1, 
                branch_id: 1, 
                last_login_date: '', 
                laste_session_update: '', 
                status: 1,
                empid: 0,
                vperc: 0,
                storeid: 1
            },
            Stores: [],
            Branches: [],
        }
    },
    computed: {
        constsCenters: function(){
            return [
                {text: 'المركز العام',value:1},
                {text: 'قسم الالماني',value:2},
            ]
        },
        isStores: function(){
            let t = [];
            for(let i=0;i<this.Stores.length;i++){
                t.push({text: this.Stores[i][`name_${this.$parent.lang.langname}`],value: this.Stores[i].id})
            }
            return t;
        },
        isBranches: function(){
            let t = [];
            for(let i=0;i<this.Branches.length;i++){
                t.push({text: this.Branches[i][`name_${this.$parent.lang.langname}`],value: this.Branches[i].id})
            }
            return t;
        }
    },
    methods: {
        userfullname(){
            this.user.full_name = this.employees[this.user.empid];
        },
        getEmp(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getEmpl");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]","all");
            axios.post(
                this.$SAMCOTEC.r_path , post
            )
            .then((res) => {
                // // console.log(res);
                this.employees = res.data.results.data;
            });
        },
        resetValues(){
            this.user = {
                id: 0,
                username: '', 
                full_name: '', 
                password: '', 
                group_id: 3, 
                created_by: 0, 
                created_date: '', 
                updated_by: 0, 
                updated_date: '', 
                company_id: 1, 
                branch_id: 1, 
                last_login_date: '', 
                laste_session_update: '', 
                status: 1,
                vperc: 0,
                cost_center: 1,
                storeid: 1,
            }
        },
        addUser(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type",'addUser');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            Object.keys(this.user).forEach(key => {
                post.append("data["+key+"]",this.user[key]);
            })
            // post.append("data[id]",this.user.id);
            // post.append("data[username]",this.user.username);
            // post.append("data[full_name]",this.user.full_name);
            // post.append("data[password]",this.user.password);
            // post.append("data[group_id]",this.user.group_id);
            // post.append("data[empid]",this.user.empid);
            // post.append("data[vperc]",this.user.vperc);
            // post.append("data[cost_center]",this.user.cost_center);
            // post.append("data[]",this.user.cost_center);
            // post.append("data[cost_center]",this.user.cost_center);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (resposnse) => {
                    const res = resposnse.data;
                    // // console.log(res);
                    this.resetValues();
                    if(res.error.number == 200){
                        this.$snotify.create({
                            title: 'اضافة / تعديل',
                            body: 'تمت العملية بنجاح',
                            config: {
                                position: SnotifyPosition.leftTop,
                                type: SnotifyStyle.success,
                                timeout: 10000,
                                showProgressBar: true,
                                closeOnClick: true,
                            }
                        })
                    }else{
                        this.$snotify.error({
                            title: 'اضافة / تعديل',
                            body: 'حصل خطأ اثناء الاضافة/التعديل',
                            config: {
                                position: SnotifyPosition.leftTop,
                                type: SnotifyStyle.success,
                                timeout: 10000,
                                showProgressBar: true,
                                closeOnClick: true,
                            }
                        })
                    }
                }
            )
        },
        getStores(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type",'getStores');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",0);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (resposnse) => {
                    if(resposnse && resposnse.data){
                        this.Stores = resposnse.data.results.data
                        this.Branches = resposnse.data.results.branches
                    }
                }
            );
        }
    },
    created() {
       // this.camp.types = {text:'رسائل واتس آب', value:'2'};
       this.getStores()
       this.getEmp()
    },
}
</script>